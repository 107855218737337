@use "sass:color";@import "brands/trapsensor/assets/styles/brand.scss";$brand:trapsensor;
@import "~assets/styles/variables.scss";

.root {
  width: 100%;
  height: 100%;
  position: relative;

  .controlPanel {
    left: 0px;
    top: 0px;
    width: 100%;
    height: 80px;
    background: color.change($mn-dark-bg, $alpha: 0.7);
    position: absolute;
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }
}
