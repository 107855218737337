@use "sass:color";@import "brands/trapsensor/assets/styles/brand.scss";$brand:trapsensor;
.root {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  table thead th {
    background-color: white !important;
    color: var(--bs-primary) !important;
    text-transform: uppercase;
  }

  table tbody td {
    vertical-align : middle;
  }
}
