@use "sass:color";@import "brands/trapsensor/assets/styles/brand.scss";$brand:trapsensor;
@import "./bootstrap";


html {
  height: 100vh;
}

body, .app-container {
  min-height: 100vh;
}

.form-switch > .form-check-input {
  vertical-align: middle !important;
}