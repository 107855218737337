@use "sass:color";@import "brands/trapsensor/assets/styles/brand.scss";$brand:trapsensor;
@import "~assets/styles/variables.scss";

.root {
  .deleteButton {
    display: none;
    position: absolute;
    z-index: 10;
    top: 50%;
    right: $spacer;
    transform: translate(0%, -50%);
  }

  tr:hover .deleteButton {
    display: block;
  }
}
