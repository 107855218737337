@use "sass:color";@import "brands/trapsensor/assets/styles/brand.scss";$brand:trapsensor;
.splitter {
  width: 8px;
  background-color: var(--bs-primary);
  position: relative;

  display: flex;
  align-items: center;

  .fab {
    position: absolute;
    z-index: 1000;

    width: 100%;
    height: 50px;
    cursor: pointer;

    background: linear-gradient(45deg, #bbb 12.5%, #fff 12.5%, #fff 37.5%, #bbb 37.5%, #bbb 62.5%, #fff 62.5%, #fff 87.5%, #bbb 87.5%);
    background-size: 4px 4px;
    background-position: 50px 50px;

    &:hover {
      box-shadow: 0 6px 14px 0 #666;
      transform: scale(1.05);
    }
  }
}
