@use "sass:color";@import "brands/trapsensor/assets/styles/brand.scss";$brand:trapsensor;
.root {
  min-height: 100vh;
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;

  .body {
    flex: 1 0 auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
  }
}
