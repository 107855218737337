@use "sass:color";@import "brands/trapsensor/assets/styles/brand.scss";$brand:trapsensor;
@import "~assets/styles/variables.scss";

.heat_0 {
  fill: #f2f2f2;
}
.heat_1 {
  fill: darken($heat-map-heat-0, 0%);
}
.heat_2 {
  fill: darken($heat-map-heat-0, 0%);
}
.heat_3 {
  fill: darken($heat-map-heat-0, 0%);
}
.heat_4 {
  fill: darken($heat-map-heat-0, 0%);
}
.heat_5 {
  fill: darken($heat-map-heat-0, 20%);
}

.heatLegend {
  fill: white;
}

.heatBody {
  & rect {
    transition: 0.3s;
  }

  & rect:hover {
    opacity: 0.7;
  }
}
