@use "sass:color";@import "brands/trapsensor/assets/styles/brand.scss";$brand:trapsensor;
@import "~assets/styles/variables.scss";

.root {
  .options {
    grid-row: 1 / span 2;

    .optionsBody {
      display: flex;
      flex-flow: column nowrap;

      row-gap: 15px;

      .table {
        background: white;
        border-radius: $mn-border-radius;

        padding: 1rem;
      }

      .tableHeader {
        height: 2.5rem;

        display: grid;
        grid-template-columns: 1fr 50px 50px;

        background: var(--primary);
        border-radius: $mn-border-radius;

        align-items: center;
        color: white;
      }

      .tableRow {
        display: grid;
        grid-template-columns: 1fr 50px 50px;
        justify-items: center;
      }

      .tableRowHeader {
        justify-self: start;
      }
    }
  }

  .destinations {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 15px;

  }

  .languages {
    grid-column: 2;
  }
}
