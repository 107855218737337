@use "sass:color";@import "brands/trapsensor/assets/styles/brand.scss";$brand:trapsensor;
@import "~assets/styles/variables.scss";

.root {
  background: $mn-dark-bg;

  .column1 {
    .column1Logo {
      background: url($mp_smalllogo_path) no-repeat center center;
      background-size: contain;
      width: 85px;
      height: 56px;
    }

    .column1Link {
      display: block;
      padding: 0.625rem;
    }
  }

  .column2, .column3, .column4  {
    & > h6 {
      color: $mn-dark-color;
    }
  }

  .column4 {
    .column4Logo {
      background: url($mp_alerthouselogo_path) no-repeat center center;
      background-size: contain;
      width: 160px;
      height: 16px;
    }
  }

  .footerText {
    color: $mn-dark-color;
  }
}
